import React from 'react'
import Layout from '../components/layout'
import { Container } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Hero from '../components/Hero'

export default ({ data }) => (
  <Layout>
    <Hero gradient="true" img={data.aboutImage.childImageSharp.fluid}></Hero>
    <Container>
      <h2>Web</h2>
      <p>Creating content with Vue, React, Photoshop, Postgres and Mongo</p>
    </Container>
    <Container>
      <h2>Video</h2>
      <p>
        Storytelling with Premiere, After Effects, Resolve, Illustrator and
        Audition
      </p>
    </Container>

    <Container alignItems="flex-end">
      <Button component={Link} to="/" size="small">
        &larr; Back
      </Button>
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    aboutImage: file(relativePath: { eq: "aboutBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
